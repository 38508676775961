/*
Dark Navy: #152238;
Darkest Blue: #51789a
Dark Blue: #779baf
Light Blue: #9fd0eb
Lightest Blue: #dfeef6
Dark Green: #4e6c49
Medium Green: #a5bc85
Light Green: #d2e6bc
Hobie Cream: #f9f0e3
Bell Grey: #c5c8c3
Bell White: #f3eee0
*/


div{
	font-family: freight-sans-pro,sans-serif;
}

html, body {
	height: 100%;
	/*text-align:center;*/
}

body {
  display: flex;
  flex-direction: column;
}

@font-face {
  font-family: arsenal;
  src: url(../assets/Arsenal-Regular.ttf.woff);
}

@font-face {
	font-family: script;
	src: url(../assets/Claudya.ttf);
}

/* other option: condor-compressed,sans-serif; */

.iconImg {
	border-radius: 50%;
	height:125px;
}

.invis {
	opacity: 0%; 
}

.app {
	height: 100vh;
    float: initial;
}

.placeholder {
	height: 100%;
	width: auto;
}

.tightWrap {
	padding-left: 10%; 
	padding-right: 10%; 
}

.headerImg
{
	padding-left: 3ch;
}

.userinput {
	background: #dfeef6; 
	border: 1px solid #51789a;
	border-radius: 4px;
	text-align: center;
	font-family: arsenal;
}

.headerLayout 
{
	width:100ch;
}



.pad-bottom
{
	padding-bottom: 1vh;
}

.hobie {
	height: 20vh;
	width: auto;
}

.Lthird{
	display: flex;
	height: auto; 
	width: 33%;
}

.Mthird{
	width: 34%;
    padding-top: 18ch;
    height: 20vh;
    flex-direction:column-reverse;
}

.Rthird{
	display: flex;
	height: auto; 
	width: 33%;
	flex-direction: row-reverse;
}

.Rimage {
	transform: rotate(90deg); 
	margin-left:auto;
	height: 20vh;
}
.flowerL
{
	height: 20vh;
}

.rowlogin {
	display: flex;
	width:100%;
}

.rowlogin:after {
  content: "";
  display: flex;
  clear: both;
  padding-bottom: 20px;
  color: #565656;
  height: 24ch;
}

.hobieButton {
	background: #51789a;
	font-family: arsenal; 
	color: #f9f0e3;
	border: 1px solid #f9f0e3;
	border-radius: 3px; 
}

.test{
	padding-top: 10vh; 
	padding-bottom: 10vh;
}

.mobileButton 
{
	width: 10ch;
	background:transparent;
	border: none;

}

.dropdownItem {
	width: 100%;
	padding-top: 1%;
	padding-bottom: 1%;
}

.row:after {
  content: "";
  display: table;
  clear: both;
  padding-bottom: 20px; 
  height: 30%;
  color: #565656;
}

.faqColumnL {

  float: left;
  width: 50%;
  height: 20vh;
  color: #565656;
  text-align:center;
  vertical-align: top;
  display: table;
  border-right: 1px solid #000;
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: #565656;;
}


.faqColumn {
  display: table;
  float: left;
  width: 49%;
  height: 20vh;
  color: #565656;
  padding-right: 0px;
    vertical-align: middle;
}

.faqsubheader
{
	font-family: arsenal,sans-serif;
	font-size: 200%; 
	font-weight: 300;
	/*font-size: 36px;*/
    /*font-weight: 300;*/
    line-height: 1.2;
    margin: 0 0 29px;
    letter-spacing: 7px;
    text-transform: uppercase;
    color: #565656;
    display: table-cell; 
    vertical-align: middle
}

.faqinfo
{
	font-weight: 300;
	padding-left: 15%;
	padding-right: 15%;
	padding-bottom: 15px;
	color: #565656;
	display: table-cell; 
	vertical-align: middle
}

.columnL {
  float: left;
  width: 50%;
  color: #565656;
  border-right: 1px solid #000;
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: #565656;;
}


.column {
  float: left;
  width: 49%;
  color: #565656;
  padding-right: 0px;
}

.columnLAct {
float: left;
width: 50%;
color: #565656;
border-right: 1px solid #000;
	border-right-width: 1px;
	border-right-style: solid;
	border-right-color: #565656;;
}


.columnAct {
float: left;
width: 49%;
color: #565656;
padding-right: 0px;
}

.columnLFull {
  float: left;
  width: 49%;
  color: #565656;
  border-right: 1px solid #000;
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: #565656;;
}


.columnFull {
  float: left;
  width: 49%;
  color: #565656;
  padding-right: 0px;
}

.headerImage{
	width: 80%;
	height: auto;
	float: center;
	padding-top: 2%;
	padding-bottom: 5%;
}

.headerImageFullWidth {
	width: 100%;
	height: auto;
	float: center;
	padding-top: 2%;
	padding-bottom: 5%;
}

.headerImageShrunk{
	width: 50%;
	height: auto;
	float: center;
	padding-top: 2%;
	padding-bottom: 5%;
}

.headerText {
	font-family: arsenal,sans-serif;
	font-size: 500%; 
	font-weight: 400;
	/*font-size: 36px;*/
    /*font-weight: 300;*/
    line-height: 1.2;
    margin: 0 0 29px;
    letter-spacing: 7px;
    text-transform: uppercase;
    color: #51789a;
}

.headerScript {
	font-family: script,sans-serif;
	font-size: 700%; 
	font-weight: 550;
    line-height: 1;
    margin: 0 0 0px;
    /*letter-spacing: 2	px;*/
    /*text-transform: uppercase;*/
    color: #51789a;
}

.footer {
	background: #f9f0e3;
	height: 40px;
	display: flex;
	justify-content: space-between;
	padding: 0.2rem calc((100vw - 1000px) / 2);
	z-index: 12;
	/*box-shadow: 0 -4px -10px 2px #9fd0eb;*/
	border-top: 1px solid #4e6c49;
		border-top-width: 1px;
		border-top-style: solid;
		border-top-color: #4e6c49;
	/* Third Nav */
	/* justify-content: flex-start; */
	flex-shrink: 0;
	grid-area: footer;
}

.form {
    padding-bottom: 31vh;
    font-family: arsenal;
    font-size: 120%;
    font-weight: 400;
}

.empty {
	padding-top: 30vh;
    padding-bottom: 50vh;
    font-family: arsenal;
    font-size: 120%;
    font-weight: 400;
}

.subheader {
	font-family: arsenal,sans-serif;
	font-size: 200%; 
	font-weight: 300;
	/*font-size: 36px;*/
    /*font-weight: 300;*/
    line-height: 1.2;
    margin: 0 0 29px;
    letter-spacing: 7px;
    text-transform: uppercase;
    color: #565656;

}

.info {
	font-weight: 300;
	padding-left: 15%;
	padding-right: 15%;
	padding-bottom: 15px;
	color: #0b0a0a;

}

.customLink {
	color: #779baf;
	font-weight: bold;
}

.hotelName {
	font-weight: 1000;
	font-family: arsenal; 
	font-size:110%;
	color: #565656;
}

.hotelAddress {
	font-weight: 100;
	/*font-style: italic;*/
	color: #565656;
}

.hotelPhone{
	font-weight:100;
	font-style: italic; 
	color: #565656;
}

.schedulePlace{
	font-weight:100;
	font-style: italic bold; 
	font-size: 120%;
	color: #565656;
}

.scheduleTime{
	font-weight:100;
	font-style: italic bold; 
	font-size: 110%;
	color: #565656;
}

.activityName {
	font-weight: 1000;
	font-family: arsenal; 
	font-size: 200%;
	color: #565656;
	padding-bottom: 3px;
}

.activityLoc{
	font-weight:100;
	font-style: italic; 
	color: #565656;
	padding-bottom: 10px;
	font-size: 125%;
	padding-left: 15%;
    padding-right: 15%;
}

.activityInfo {
	font-weight: 100;
	font-size: 80%;
	/*font-style: italic;*/
	color: #565656;
}

.scheduleBlock {
	font-family: arsenal,sans-serif;
	font-weight: 300;
	padding-left: 15%;
	padding-right: 15%;
	padding-bottom: 15px;
	color: #565656;
	border-bottom: 0px solid #000;
	  border-bottom-width: 0px;
	  border-bottom-style: solid;
	  border-bottom-color: #565656;
}

.scheduleSubheader {
	font-family: arsenal,sans-serif;
	font-size: 200%; 
	font-weight: 300;
	/*font-size: 36px;*/
	/*font-weight: 300;*/
	line-height: 1.2;
	margin: 0 0 12px;
	letter-spacing: 7px;
	text-transform: uppercase;
	color: #565656;
}

@media screen and (max-width: 768px) {

	.columnLFull {
	  float: left;
	  width: 49%;
	  color: #565656;
	  border-right: 0px solid #000;
	    border-right-width: 0px;
	    border-right-style: solid;
	    border-right-color: #565656;;
	}


	.columnFull {
	  float: left;
	  width: 49%;
	  color: #565656;
	  padding-right: 0px;
	}

	.faqColumnL {

	  float: left;
	  width: 49%;
	  height: 40vh;
	  color: #565656;
	  text-align:center;
	  vertical-align: top;
	  display: table;
	  border-right: 1px solid #000;
	    border-right-width: 1px;
	    border-right-style: solid;
	    border-right-color: #565656;;
	}

	.faqColumn {
	  display: table;
	  float: left;
	  width: 49%;
	  height: 40vh;
	  color: #565656;
	  padding-right: 0px;
	    vertical-align: middle;
	}

	.faqsubheader
	{
		font-family: arsenal,sans-serif;
		font-size: 150%; 
		font-weight: 300;
		/*font-size: 36px;*/
	    /*font-weight: 300;*/
	    line-height: 1.2;
	    margin: 0 0 29px;
	    letter-spacing: 7px;
	    text-transform: uppercase;
	    color: #565656;
	    display: table-cell; 
	    vertical-align: middle
	}

	.faqinfo
	{
		font-weight: 300;
		padding-left: 15%;
		padding-right: 15%;
		padding-bottom: 15px;
		color: #565656;
		display: table-cell; 
		vertical-align: middle
	}

	.columnL {
	  float: left;
	  width: 100%;
	  color: #565656;
	  display: table-cell;
	  text-align: center;
	  vertical-align: middle; 
	  height: 12vh;
	  padding-bottom: 5%; 
	  padding-top: 5%; 
	  border-right: 0px solid #000;
	    border-right-width: 0px;
	    border-right-style: solid;
	    border-right-color: #565656;
	}


	.column {
	  float: left;
	  display: table-cell;
	  text-align: center;
	  vertical-align: middle; 
	  height: 12vh;
	  width: 100%;
	  color: #565656;
	  padding-right: 0px;
	  padding-bottom: 5%; 
	  padding-top: 5%; 
	}

	.columnLAct {
		float: left;
		width: 100%;
		color: #565656;
		display: table-cell;
		text-align: center;
		vertical-align: middle; 
		padding-bottom: 5%; 
		padding-top: 5%; 
		border-right: 0px solid #000;
		  border-right-width: 0px;
		  border-right-style: solid;
		  border-right-color: #565656;
	  }
  
  
	  .columnAct {
		float: left;
		display: table-cell;
		text-align: center;
		vertical-align: middle; 
		width: 100%;
		color: #565656;
		padding-right: 0px;
		padding-bottom: 5%; 
		padding-top: 5%; 
	  }

	.scheduleBlock {
		font-family: arsenal,sans-serif;
		font-weight: 300;
		padding-left: 15%;
		padding-right: 15%;
		padding-bottom: 15px;
		color: #565656;
		border-bottom: 0px solid #000;
		  border-bottom-width: 0px;
		  border-bottom-style: solid;
		  border-bottom-color: #565656;
	}

	.scheduleSubheader {
		font-family: arsenal,sans-serif;
		font-size: 200%; 
		font-weight: 300;
		/*font-size: 36px;*/
		/*font-weight: 300;*/
		line-height: 1.2;
		margin: 0 0 5px;
		letter-spacing: 7px;
		text-transform: uppercase;
		color: #565656;
	}

	.row:before,
	.row:after {
		content: "";
		display: table;
	}
	
	.row:after {
		clear: both;
	}

	.hotelName {
	font-weight: 1000;
	font-family: arsenal; 
	font-size: 100%;
	color: #565656;
	}

	.hotelAddress {
		font-weight: 100;
	    font-size: 80%;
		/*font-style: italic;*/
		color: #565656;
	}

	.hotelPhone{
		font-weight:100;
		font-style: italic; 
	    font-size: 80%;
		color: #565656;
	}

	.schedulePlace{
		font-weight:100;
		font-style: italic bold; 
		font-size: 110%;
		color: #565656;
	}

	.scheduleTime{
		font-weight:100;
		font-style: italic bold; 
	    font-size: 110%;
		color: #565656;
	}

	.activityName {
		font-weight: 1000;
		font-family: arsenal; 
		font-size: 200%;
		color: #565656;
	}

	.activityInfo {
		font-weight: 100;
		font-size: 80%;
		/*font-style: italic;*/
		color: #565656;
	}

	.headerImageShrunk{
		width: 100%;
		height: auto;
		float: center;
		padding-top: 2%;
		padding-bottom: 5%;
	}

	.Mthird{
		display:flex;
	    padding-top: 18ch;
	    height: 20vh;
	    width:34%%;
	}

	.Lthird{
		display: flex;
		width: 33%;
	}

	.Rthird{
		display: flex;
		width: 33%;
		flex-direction: row-reverse;
	}

	.hobie {
		width: auto;
		height: auto;
		max-height: auto;
		max-width: 100%;
	}

	.iconImg {
		border-radius: 50%;
		height:12vh;
	}

	.iconImg::before
	.iconImg::after {
		content: "";
		display: table;
	}

	.headerText {
		font-family: arsenal,sans-serif;
		font-size: 450%; 
		font-weight: 400;
		/*font-size: 36px;*/
		/*font-weight: 300;*/
		line-height: 1.2;
		margin: 0 0 29px;
		letter-spacing: 7px;
		text-transform: uppercase;
		color: #51789a;
	}


	    
} /* Media */

